<template>
  <div class="synth-report-search-container">
    <!-- <SearchTemplate @changequery="changequery" :query="searchquery" /> -->
    <div class="search_wrapper">
      <a-range-picker
        class="search_item"
        format="YYYY-MM-DD"
        :placeholder="['Start', 'End']"
        @change="ChangeDate"
        :value="rangePickerDate"
        :allowClear="false"
        :ranges="{
          今日: [initDate(0, 0).start, initDate(0, 0).end],
          昨日: [initDate(1, 1).start, initDate(1, 1).end],
          最近一周: [initDate(6, 0).start, initDate(6, 0).end],
          最近一个月: [initDate(30, 0).start, initDate(30, 0).end],
          上一个月: getLastMonthDuration()
        }"
        :disabledDate="disabledDate"
      >
        <!-- <a-icon slot="suffixIcon" type="calendar" /> -->
      </a-range-picker>

      <a-select
        class="search_item"
        :allowClear="true"
        placeholder="请选择账号"
        v-model="searchquery.adminUserIds[0]"
        v-if="getIsAdmin()"
        @change="changeUserId"
        show-search
        option-filter-prop="children"
        :filter-option="filterOption"
      >
        <a-select-option v-for="item in adminList" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
      </a-select>
      <m-select-more
        class="search_item"
        v-model="searchquery.appIdList"
        :allData="appList"
        :searchById="true"
        :hasIcon="true"
        :showId="true"
        width="450px"
        label="应用"
        @change="changeAppIds"
      />
      <m-select-more
        class="search_item"
        v-model="searchquery.placeIdList"
        :allData="placeList"
        :searchById="true"
        :hasIcon="true"
        :showId="true"
        width="450px"
        label="广告位"
        @change="changePlaceIds"
      />
      <m-select-more
        class="search_item"
        v-model="searchquery.groupIdList"
        :allData="groupList"
        :searchById="true"
        :hasIcon="true"
        :showId="true"
        width="450px"
        label="流量分组"
        @change="handleAutoSearch"
      />
      <m-select-more
        class="search_item"
        v-model="searchquery.sourceIdList"
        :allData="sourceList"
        :searchById="true"
        :hasIcon="false"
        :showId="true"
        label="广告源"
        :showSelectedPart="true"
        width="450px"
        :canSelectAll="true"
        @change="handleAutoSearch"
      />
      <m-select-more
        class="search_item"
        v-model="searchquery.channelIdList"
        :allData="channelList"
        :searchById="false"
        :hasIcon="false"
        :showId="false"
        label="渠道"
        :showSelectedPart="true"
        width="450px"
        :canSelectAll="true"
        @change="handleAutoSearch"
      />
      <m-select-more
        class="search_item"
        v-model="searchquery.positionIdList"
        :allData="positionList"
        :searchById="false"
        :hasIcon="false"
        :showId="false"
        label="广告样式"
        :showSelectedPart="true"
        width="450px"
        :canSelectAll="true"
        @change="handleAutoSearch"
      />
      <m-select-more
        class="search_item"
        v-model="searchquery.platIdList"
        :allData="platList"
        :searchById="false"
        :hasIcon="false"
        :showId="false"
        label="广告平台"
        :showSelectedPart="true"
        width="450px"
        :canSelectAll="true"
        @change="handleAutoSearch"
      />
      <m-select-more
        class="search_item"
        v-model="searchquery.osIdList"
        :allData="osList"
        :searchById="false"
        :hasIcon="false"
        :showId="false"
        label="操作系统"
        :showSelectedPart="false"
        width="260px"
        :canSelectAll="false"
        @change="handleAutoSearch"
      />
      <div class="search_item input">
        <a-input placeholder="请输入SDK版本号" v-model.trim="searchquery.sdkVersion" @input="changeInputValue" />
      </div>
      <div class="search_item input">
        <a-input placeholder="请输入应用版本" allowClear v-model.trim="searchquery.appVersion" @input="changeInputValue" />
      </div>
    </div>
  </div>
</template>

<script>
// // import SearchTemplate from './SearchTemplate'
import { mapState } from 'vuex'
import mixDate from '@/mixins/initDate'
import mixGetList from '@/mixins/getList'
import { getDspList } from '@/api/common'
import { getDuration } from '@/utils/duration'
export default {
  // components: { SearchTemplate },
  mixins: [mixDate, mixGetList],
  data () {
    return {
      dspPlaceList: [],
      searchquery: {
        dateEnd: '',
        dateStart: '',
        adminUserIds: [],
        appIdList: [],
        dspPlaceIdList: [],
        placeIdList: [],
        groupIdList: [],
        sourceIdList: [],
        osIdList: [],
        channelIdList: [],
        positionIdList: [],
        platIdList: [],
        sdkVersion: '',
        appVersion: ''
      },
      osList: [
        { name: '安卓', id: '1' },
        { name: 'IOS', id: '0' }
      ],
      timer: null,
      isTemplate: false
    }
  },
  computed: {
    ...mapState({
      adminUserId: (state) => state.user.adminUserId,
      role: (state) => state.user.roles[0],
      title: (state) => state.autoweb.title
    })
  },
  async created () {
    // 初始化时间
    const { endDate, startDate, end, start } = this.initDate(7, 1)
    this.rangePickerDate = [start, end]
    this.searchquery.dateEnd = endDate
    this.searchquery.dateStart = startDate
    if (this.getIsAdmin()) {
      this.getAdminList()
    }
    this.getAppList()
    this.getPlaceList()
    this.getGroupList()
    this.getSourceList()
    this.getChannelList()
    this.getPositionList()
    this.getPlatList()
    this.getDspList()
    // 进入时搜索
    this.$emit('handleAutoSearch', this.searchquery)
  },
  methods: {
    // 判断是否是超管及创建的子账户
    getIsAdmin () {
      return this.role === 'SUPER_ADMIN' || (this.adminUserId != null && this.adminUserId === 1)
    },
    filterOption (input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    changeInputValue () {
      this.timer && clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.handleAutoSearch()
      }, 1000)
    },
    // 不可选时间
    disabledDate (current) {
      // 默认大于今天的时间 小于2021.9
      return (current && current < this.initDate(getDuration(), 0).start) || current > this.initDate(0, 0).end
    },
    ChangeDate (date, dateString) {
      this.searchquery = {
        ...this.searchquery,
        dateEnd: dateString[1],
        dateStart: dateString[0]
      }
      this.rangePickerDate = date
      this.handleAutoSearch()
    },
    // 账户改变查应用
    async changeUserId (id) {
      if (this.searchquery.adminUserIds[0]) {
        // userId有值，查app
        await this.getPlatListByuser({ id })
        await this.getAppListByAdminId(id)
      } else {
        // userId为空，查app
        await this.getPlatList()
        await this.getAppList()
      }
      await this.changeAppIds(this.searchquery.appIdList)
    },
    // 应用改变查广告位
    async changeAppIds (ids) {
      if (ids.length === 0) {
        // app无值 通过全部appList查place列表
        await this.getPlaceListByAppIds(this.appList.map((item) => item.id))
      } else {
        // app选了 通过选了的app查place列表
        await this.getPlaceListByAppIds(ids)
      }
      this.changePlaceIds(this.searchquery.placeIdList)
    },
    // 广告位改变查流量分组
    async changePlaceIds (ids) {
      if (ids.length === 0) {
        // 广告位无值，通过全部placelist查询group列表
        // await this.groupListByAdplace(this.placeList.map((item) => item.id))
        await this.groupListByAdplace(this.placeList.map((item) => item.id))
        await this.getSourceListByAdplace(this.placeList.map((item) => item.id))
      } else {
        // 广告位选了，通过选中的place查询group列表
        await this.groupListByAdplace(ids)
        await this.getSourceListByAdplace(ids)
      }
      this.handleAutoSearch()
    },
    async getDspList () {
      const { data = [] } = await getDspList()
      data.forEach((item) => {
        // 展示的是platPlaceId
        item.searchId = item.platPlaceId
      })
      this.dspPlaceList = data.map((item) => ({ searchId: item.platPlaceId, name: item.name, id: item.id }))
    },
    handleAutoSearch () {
      // 判断是否是模板条件，若是模板条件则不进行查询
      if (this.isTemplate) {
        this.isTemplate = false
        return
      }
      this.os && this.searchquery.osIdList.push(this.os)
      this.$emit('handleAutoSearch', this.searchquery)
    }
  }
}
</script>

<style lang="less" scoped>
.synth-report-search-container {
  border-radius: 5px;
  overflow: hidden;
  .search_wrapper {
    background: #fff;
    padding: 10px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
    gap: 10px;
    .search_item{
      width: 100%;
    }
  }
}
</style>
